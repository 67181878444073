import React, { useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import styled from 'styled-components';
import Button from '../../components/buttons/linkButton'
import { Linkedin, Github } from 'react-bootstrap-icons';
import { Link } from "gatsby"

const StudentColumn = styled(Col)`
  margin-top: 50px;
`
const VideoContainer = styled(Container)`
  padding-top: 30px;
  max-width: 500px;
  h3 {
    text-align: center;
  }
  .cta-container {
    text-align: center; 
  }
  img {
    border: 4px solid var(--black);
    box-shadow: -15px 15px var(--black);
  }
`

const Student = ({student}) => {
  const [showDeets, updateDeets] = useState(false);

  const handleToggleDeetsClick = () => {
    updateDeets(!showDeets);
  }

  const renderShowButton = () => {
    if (showDeets) {
      return <span>Hide Details and Demo</span>
    } else {
      return <span>Show Details and Demo</span>
    }
  }

  const renderToggleDeets = () => {
    if (showDeets) {
      return (
        <p className='deets'>
          <Row>
            <Col>
              <a href={student.linkedin} target='_blank'>
                <Button classToAdd='linkedin' text='LinkedIn'><Linkedin/></Button>
              </a>
            </Col>
            <Col>
              <a href={student.github} target='_blank'>
                <Button text='GitHub'><Github/></Button>
              </a>
            </Col>
          </Row>
          <div className='bio'>
            <p className='bio-title'>Bio:</p>
            {student.gradbio}
          </div>
          <hr></hr>
          <div className='final-project'>
            <span className='final-project-title'>Final Project: </span>{student.projecttitle}
            <br></br>
            <br></br>
            <span className='final-project-tech'>Technologies: </span>{student.projecttech}
            <br></br>
            <br></br>
            {student.projectdescription}
          </div>
          <VideoContainer>
            <iframe width="100%" height="230" src={'https://www.youtube.com/embed/' + student.youtubeid} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </VideoContainer>
        </p>
      )
    }
  }

  return (
    <StudentColumn md={6}>
      <div className='test-container'>
        <div className='test-img-box'>
          <img alt='' src={student.image}></img>
        </div>
        <div className='test-info-container'>
          <p className='grad-name'>
            {student.name}
          </p>
        </div>
        {renderToggleDeets()}
        <div className='show-more-button' tabIndex={0} role='button' onClick={handleToggleDeetsClick} onKeyDown={handleToggleDeetsClick}>
          <p>{renderShowButton()}</p>
        </div>
      </div>
    </StudentColumn>
  )
};

export default Student;