// import Connor from '../assets/images/grads/connor.jpg'
import Stef from "../assets/images/grads/stef.jpg"
// import Josh from '../assets/images/grads/josh.jpg'
// import Mike from '../assets/images/grads/mike.jpg'
import Abe from "../assets/images/grads/abe.jpg"

import Lauren from "../assets/images/lauren-n.jpg"
import Danna from "../assets/images/danna-a.jpg"
// import Jeremy from '../assets/images/jeremy-w.jpg'

const students = [
  {
    image: Danna,
    firstName: "Danna",
    lastName: "Alvarado",
    bio: "I’ve always had a curiosity about how things work. As a nurse, initially that curiosity was directed at medicine. However, after a conversation with a young dying patient, I realized that life and time is a gift. That week I learned about a local boot camp in Durham and made the decision to commit to Parsity and pursue an interest in technology that I’ve had since childhood. Parsity exposed me to the tools and a way of thinking that I believe will make me successful wherever I go. To look at problems as possibilities, for efficiency and elegance, and to detach and look up and out before stepping forward. I’ve gained a new perspective that will serve as a foundation for problem-solving and continuous learning in my career.",
    projectDescription:
      "Co-Health is a full-stack application that helps bridge the gap in community healthcare by connecting individuals to healthcare professionals in their community. Healthcare professional license verification is completed by the app. Co-health connects individuals using Google Maps and Geolocation and allows users to make healthcare need requests or offer their volunteer healthcare services. Once connected, users are able to have an instant real-time chat.",
    projectTitle: "Co-Health",
    technologies:
      "React, Redux, Node, Express, MongoDB, Google Maps API, Google Geolocation API,  Socket I.O, Bootstrap",
    linkedIn: "https://www.linkedin.com/in/dalva19/",
    github: "https://github.com/dalva19",
    video: "https://www.youtube.com/embed/foZXCPu6Yfw",
  },
  {
    image: Lauren,
    firstName: "Lauren",
    lastName: "Nicholls",
    bio: "I’ve always had a passion for continual learning and that has served me well as an educator and an engineer. A little over a year ago, I discovered software engineering; a field that has captivated my attention because it allows me to express my creativity, gives me immediate feedback, fuels my desire to learn new things and is mentally stimulating. Parsity has taken that passion and helped gradually build me up to developing full stack applications. I am eager to continue to learn new technologies and frameworks and looking forward to working with individuals who share that same passion.",
    projectDescription:
      "Therapy&Me is a platform for all of your therapy needs. Users can maintain a journal, track their feelings, create an agenda for therapy sessions, and maintain a board for adding ways to manage stress and anxiety.",
    projectTitle: "Therapy&Me",
    technologies:
      "React, Redux, Highcharts, Bootstrap, DraftJS, TypeScript, Node, Express, MongoDB",
    linkedIn: "https://www.linkedin.com/in/lauren-nicholls-89283913/",
    github: "https://github.com/lmnicholls",
    video: "https://www.youtube.com/embed/N3GhawuIB20",
  },
  {
    image: Stef,
    firstName: "Stefania",
    lastName: "Spetetchi",
    bio: "For almost 8 years I worked in Investment Banking, managing multiple teams in high-risk critical functions. The goal was always to improve our processes and do things better and faster, more often than not involving our technology and software. I led multiple such projects, working with developers and Project Managers to achieve that, and that sparked the idea of learning software development and changing careers. I decide to join Parsity due to its thorough curriculum, and during the course learnt to apply past skills to coding problems, such as deductive reasoning and attention to detail. Software engineering opens a realm of possibilities and continuous growth in this ever-evolving industry and I am excited to discover new opportunities!",
    projectDescription:
      "Closet Binder is a high-level virtual depiction of your actual wardrobe, where you can upload pictures of each item in your closet, categorize them and create outfits for every occasion. The app helps you easily identifying gaps, shopping needs and rediscover forgotten clothing items.",
    projectTitle: "Closet Binder",
    technologies:
      "React, Redux, Cloudinary, Multer, Node, Express, MongoDB, Bootstrap",
    linkedIn: "https://www.linkedin.com/in/stefania-spetetchi-a774543b/",
    github: "https://github.com/stefania-spetetchi",
    video: "https://www.youtube.com/embed/5XybsNb6SSE",
  },
  {
    image: Abe,
    firstName: "Abrahim",
    lastName: "Esseidi",
    bio: "After gaining a solid foundation working in family businesses I embarked on my own ventures and conquered different trades. I have independently worked as an electrician, graphic designer,  led lighting specialist,  business surveillance security specialist, retail products wholesaler, truck driver.  Throughout these ventures I have always had a passion for technology. After trying some coding with javascript I fell in love with the problem solving.  I knew for me to be successful in it I would have to immerse myself fully in this field.",
    projectDescription:
      "Halal-Finder is a mobile first web application that populates a map from a verified database with markers of all the nearby restaurants in that searched locality.",
    projectTitle: "Halal-Finder",
    technologies:
      "React, Redux, Bootstrap, React NPM Libraries, Node/Express, MongoDB/Mongoose, Git/Github",
    linkedIn: "https://www.linkedin.com/in/abrahim-esseidi/",
    github: "https://github.com/abeK021",
    video: "https://www.youtube.com/embed/Lz80_6AlrkE",
  },
]

export default students
