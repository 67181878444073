import React, { useState, useEffect } from 'react'
import Layout from '../../components/layouts/courseLandingLayout'
import styled from 'styled-components';
import Student from '../../components/students/student-grad';
import { Container, Col, Row } from 'react-bootstrap';
import MattBig from '../../assets/images/matt-big.png';
import Navbar from '../../components/navbars/studentGradsNavbar';
import GradsData from '../../utils/grad-data'
 
const Grads = styled.div`
  .title {
    padding-top: 40px;
    text-align: center;
  }

  .sub-text {
    text-align: center;
    margin: 0 auto;
    max-width: 800px;
  }
`

const VideoContainer = styled(Container)`
  padding-top: 30px;
  max-width: 800px;
  h3 {
    text-align: center;
  }
  .cta-container {
    text-align: center; 
  }
  img {
    border: 4px solid var(--black);
    box-shadow: -15px 15px var(--black);
  }
`

const CourseContainer = styled.div`
  display: block;
  height: 700px;
  background: none;
  background-image: url(${MattBig});
  background-size: cover;
  background-position: center center;
`

const HeroContainer = styled(Container)`
  padding-top: 250px;
  max-width: 1000px;
  font-family: sans-serif;

  .col-hero-text {  
    padding-left: 40px;
    h1 {
      padding-top: 20px;
      padding-bottom: 20px;
      color: var(--white);
    }
    .subtext {
      color: var(--white);
    }
    .cta-syllabus {
      margin-left: 20px;
      @media screen and (max-width: 767px) {
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 24px;
    }
    img {
      max-width: 400px;
      display: block;
      margin: 0 auto;
      margin-bottom: 40px;
    }
  } 
`

const StudentsContainer = styled.section`
  background-color: var(--yellow);

  padding-bottom: 80px;
  .support-row {
    margin-top: 80px;
    p {
      text-align: left;
    }
    h2 {
      text-align: left;
    }
    .support-text {
      font-size: 16px;
    }
  }
  p {
    padding-top: 20px;
    text-align: center;
  }

  .btn-apply-curriculum {
    margin-bottom: 40px;
    margin-top: 40px;
  }
`
const AgileProjectContainer = styled.section`
  #agile {
    padding-bottom: 20px;
  }
`

const StudentsSection = styled.section`
  background-color: var(--yellow);

  hr {
    max-width: 90%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h2 {
    margin-top: 40px;
    text-align: center;
    font-weight: bold;
    color: var(--white);
  }
  
  .test-container {
    border: 5px solid var(--black);
    margin: 20px;
    background-color: #fff;
    box-shadow: -15px 15px var(--black);
    position: relative;
  }
  .test-text {
    padding: 0px 20px 0px 20px;
    margin: 0;
    font-size: .875em;
  }
  .deets {
    text-align: left;
    padding: 0px 20px 20px 20px;
    margin: 0;
    font-size: .875em;

    .final-project {
      padding-bottom: 15px;
    }

    .bio-title, .final-project-title {
      font-weight: bold;
      text-align: left;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .final-project-tech {
      font-weight: bold;
      text-align: left;
      padding-top: 0;
    }

    .linkedin {
      margin-right: 10px;
    }
  }
  .test-img-box {
    margin-top: 10px;

    img {
      display: block;
      max-width: 250px;
      max-height: 250px;
      margin: 0 auto;
    }
  }
  .test-info-container {
    padding: 0px 20px 0px 20px;
  }
  .grad-name {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    padding-bottom: 5px;
  }
  .test-company {
    img {
      width: 100%;
      max-width: 150px;
      display: block;
      margin: 0 auto;
    }
  }
  .show-more-button {
    border-top: 1px solid var(--black);
    &:hover {
      background-color: var(--blue);
      cursor: pointer;

      p {
        color: var(--white);
      }
    }

    p {
      margin: 0;
      padding: 10px;
    }
  }
  @media screen and (max-width: 767px) {
    .test-padding-top {
      margin-top: 100px;
    }
  }
`

const Index = ({location}) => {
  const [students, updateStudents] = useState([]);

  useEffect(() => {
    // figure this out later: https://juliangaramendy.dev/blog/use-promise-subscription
    let isSubscribed = true
    
    fetch('https://parsity-grad-server.herokuapp.com/grads/all')
      .then(response => response.json())
      .then((data) => {
        console.log(students)
        if (isSubscribed) {
          updateStudents(data.students);
        }
      });

      return () => isSubscribed = false
  }, []);
  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e) => setShow(true);

  const renderStudents = () => {
    if (students.length > 0) {
      return students.map(function (s) {
        return <Student student={s} key={s._id} />
      });
    } else {
      return <h3>Loading students... This might take a minute!</h3>
    }
  }

  return (
    <Layout>
      <Grads>
        <CourseContainer>
          <Navbar cohort={1} location={location}/>
          <HeroContainer>
            <Row>
              <Col className='col-hero-text text-center' md={12}>
                <h1>Parsity Graduates_</h1>
              </Col>
            </Row>
          </HeroContainer>
        </CourseContainer> 
        <StudentsContainer>
          <Container>
            <Row>
              <Col md={12}>
                <h2 className='title' id='students'>Recent Graduates</h2>
                <StudentsSection>
                  <Container>
                    <Row>
                      {renderStudents()}
                    </Row>
                  </Container>
                </StudentsSection>
              </Col>
            </Row>
          </Container>
        </StudentsContainer>
        <AgileProjectContainer>
            <Row>
              <Col md={12}>
              <h2 className='title' id='agile'>Agile Projects</h2>
              <p className='sub-text'>In addition to their indiviudal final projects (shown above) students spend 2 to 4 weeks (depending on Full-time or Part-time) building one project as a large, self-organized, cohesive team of developers. Using what they've learned, they manage their own workflows, burndown charts, stand-up meetings and pull-request reviews to deliver a working prototype on a tight deadline.</p>
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col md={12}>
              <h2 className='title'>Cohort 1 Full-time Agile Project</h2>
                <VideoContainer>  
                  <iframe width="100%" height="435" src="https://www.youtube.com/embed/YKjGvSLKIfA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </VideoContainer>
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col md={12}>
              <h2 className='title'>Cohort 1 Part-time Agile Project</h2>
                <VideoContainer>  
                  <iframe width="100%" height="435" src="https://www.youtube.com/embed/Sg3oYPVpjKk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </VideoContainer>
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col md={12}>
              <h2 className='title'>Cohort 2 Full-time Agile Project</h2>
                <VideoContainer>  
                  <iframe width="100%" height="435" src="https://www.youtube.com/embed/Drj5qRHKqTw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </VideoContainer>
              </Col>
            </Row>
        </AgileProjectContainer>
      </Grads>
    </Layout>
  )
}

export default Index
